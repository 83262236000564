import MemoryIcon from '@material-ui/icons/Memory'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import TuneIcon from '@material-ui/icons/Tune'
import AssignmentIcon from '@material-ui/icons/Assignment'
import CheckCirclIcon from '@material-ui/icons/CheckCircleOutlineOutlined'
import { Map as MapIcon, PlusCircle, MinusCircle } from 'react-feather'
import PlantComponents from './PlantComponents'
import PlantFeatures from './PlantFeatures'
import PlantContract from './PlantContract'
import PlantPlanimetry from './PlantPlanimetry'
import PlantConfirmation from './PlantConfirmation'
import { IconButton, makeStyles, TableCell, TableRow, InputBase, Radio, Tooltip, Box } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { changeDistributionPower, handleAddColumn, handleRemoveColumn, modifyDistribution } from '@slices/pv/plantCreation'
import { getDevices } from '@utils/plantOperations'
import { useSelector } from '@/store'
import PlantInfo from './PlantInfo'
import { withStyles } from '@material-ui/styles'
import { basicColors } from '@theme'

const LightTableCell = withStyles(() => ({
  head: {
    backgroundColor: '#BBE4F1',
    color: basicColors.blueGrayDark,
    fontWeight: '600'
  },
  body: {
    backgroundColor: basicColors.lightBlue2,
    color: basicColors.blueGrayDark
  }
}))(TableCell)

// const SummaryLightTableCell = withStyles(() => ({
//   head: {
//     backgroundColor: '#BBE4F1',
//     color: basicColors.blueGrayDark,
//     fontWeight: '600',
//     fontSize: '0.75rem',
//     lineHeight: 1.2
//   },
//   body: {
//     backgroundColor: basicColors.lightBlue2,
//     color: basicColors.blueGrayDark,
//     fontSize: '0.75rem',
//     lineHeight: 1.2
//   }
// }))(TableCell)

const DarkTableCell = withStyles(() => ({
  head: {
    backgroundColor: basicColors.darkGreen,
    color: 'white',
    fontWeight: '600'
  },
  body: {
    backgroundColor: basicColors.primary,
    color: 'white',
    fontWeight: '600'
  }
}))(TableCell)

const SummaryDarkTableCell = withStyles(() => ({
  head: {
    backgroundColor: basicColors.darkGreen,
    color: 'white',
    fontWeight: '600',
    fontSize: '0.75rem',
    lineHeight: 1.2
  },
  body: {
    backgroundColor: basicColors.primary,
    color: 'white',
    fontWeight: '600',
    fontSize: '0.75rem',
    lineHeight: 1.2
  }
}))(TableCell)

export const steps = [
  {
    label: 'Anagrafica',
    icon: AssignmentIcon
  },
  {
    label: 'Componenti',
    icon: MemoryIcon
  },
  {
    label: 'Composizione',
    icon: InfoIcon
  },
  {
    label: 'Par. Contrattuali',
    icon: TuneIcon
  },
  {
    label: 'Planimetria',
    icon: MapIcon
  },
  {
    label: 'Conferma Dati',
    icon: CheckCirclIcon
  }
]

// funzione che prende le chiavi di un oggetto e fa il match delle chiavi con quelle presenti nell'oggetto degli headers della tabella
export const getTableHeaders = (module) => {
  // Oggetto dei titoli della tabella
  const headers = {
    voc: 'Voc (V)',
    vmp: 'Vmp (V)',
    isc: 'Isc (A)',
    imp: 'Imp (A)',
    efficency: 'Efficienza del modulo (%)',
    maxPower: 'Potenza Massima (W)',
    maxVoltage: 'Voltaggio Massimo (V)',
    temp: 'Coefficiente temperatura (%/°C)',
    tresholdTemp: 'Temperatura di soglia (°C)'
  }

  // Funzione di reduce che ritorna un array contente i titoli da visualizzare nella tabella
  return Object.keys(module).reduce((acc, key) => {
    const currentHeader = headers[key]
    if (currentHeader) {
      acc.push(currentHeader)
    }

    return acc
  }, [])
}

export const selectActiveSection = (activeStep) => {
  switch (activeStep) {
    case 0: {
      return <PlantInfo />
    }
    case 1: {
      return <PlantComponents />
    }
    case 2: {
      return <PlantFeatures />
    }
    case 3: {
      return <PlantContract />
    }
    case 4: {
      return <PlantPlanimetry />
    }
    case 5: {
      return <PlantConfirmation />
    }
    default:
      return null
  }
}

const useStyles = makeStyles((theme) => ({
  iconButton: {
    margin: theme.spacing(1)
  },
  addIcon: {
    color: theme.palette.primary.main,
    width: 16,
    height: 16
  },
  deleteIcon: {
    color: theme.palette.alert.error,
    width: 16,
    height: 16
  },
  input: {
    color: basicColors.blueGrayDark
  }
}))

// Funzione che crea le tabelle di distribuzione di stringa per la view di conferma dati
// Prende in ingresso, i dataloggers o i devices e la distribuzione di stringa
export const confirmationDistributionsTable = (devices = null, distribution, dataloggers, location = 'dialog') => {
  // Ricavo tutti gli inverter per l'impianto
  const inverters = getDevices({ devices: devices || null, dataloggers, type: 'Inverter' })

  // Ciclo sulle chiavi primarie di ditribuzione che corrispondono agli uuid degli inverter
  return Object.keys(distribution).map(distKey => {
    // Numero di colonne (MPPT) per l'inverter corrente
    const headerCols = distKey !== '' ? distribution[distKey].headerCols : null
    // Righe della tabella di distribuzione, è un array di array dove ogni elemento corrisponde ad una stringa, e ogni elemento della stringa è associato ad un MPPT
    const rows = distKey !== '' ? distribution[distKey].rows : null
    // Prendo le informazioni (nome) riguardanti l'inverter corrente in base alla chiave su cui sto ciclando
    const currentInverter = inverters.find(inverter => inverter.uuid === distKey)
    const currentName = currentInverter ? currentInverter.name : ''

    const tableHeader = Array(headerCols)
      .fill('')
      .map((header, index) => {
        if (location === 'summary') {
          return <SummaryDarkTableCell key={`MPPT-${index + 1}`} align='center'>{`MPPT${index + 1}`}</SummaryDarkTableCell>
        } else {
          return <DarkTableCell key={`MPPT-${index + 1}`} align='center'>{`MPPT${index + 1}`}</DarkTableCell>
        }
      })
      // .map((header, index) => <DarkTableCell key={`MPPT-${index + 1}`} align='center'>{`MPPT${index + 1}`}</DarkTableCell>)

    const newRows = rows.map((el, stringIndex) => el.map((valueEl, mpptIndex) => ({
      string: stringIndex + 1,
      mppt: mpptIndex + 1,
      value: valueEl
    })))
      .flat()
      .filter(el => el.value !== 0)

    const rowsByMppt = []

    for (let i = 0; i < headerCols; i++) {
      const mpptElements = newRows.filter(el => el.mppt === i + 1)
      rowsByMppt.push(mpptElements)
    }

    // Variabile che contiene il massimo numero di righe (numero massimo di stringhe appartenenti ad un MPPT) della tabella
    const rowMax = rowsByMppt.reduce((acc, row) => {
      if (row.length > acc) {
        acc = row.length
      }
      return acc
    }, 0)

    const tableRowsExample = Array(rowMax).fill('').map((el, rowIndex) => {
      const currentRow = tableHeader.map((headEl, mpptIndex) => rowsByMppt[mpptIndex][rowIndex])

      return currentRow
    })

    const tableRows = Array(rowMax).fill('').map((el, rowIndex) => {
      const currentRow = tableHeader.map((headEl, mpptIndex) => rowsByMppt[mpptIndex][rowIndex])

      return currentRow
    })
      .filter(el => el.length > 0)
      .map((rowEl, index) => (
        <TableRow key={`row-${index}`}>
          {rowEl.map((cell, cellIndex) => cell
            ? (
              <TableCell style={{ padding: 0 }} key={`row-name-${index}-${cellIndex}`}>
                <Box width='100%' display='flex'>
                  <Box
                    py='6px'
                    px={2}
                    width='100%'
                    height='100%'
                    textAlign='right'
                    style={location === 'summary'
                      ? {
                          backgroundColor: basicColors.darkGreen,
                          color: 'white',
                          fontWeight: '600',
                          fontSize: '0.75rem',
                          lineHeight: 1.2
                        }
                      : {
                          backgroundColor: basicColors.darkGreen,
                          color: 'white',
                          fontWeight: '600'
                        }}
                  >
                    {Number(cell.string) < 10 ? `S0${cell.string}` : `S${cell.string}`}
                  </Box>
                  <Box
                    py='6px'
                    px={2}
                    width='100%'
                    height='100%'
                    textAlign='left'
                    style={location === 'summary'
                      ? {
                          backgroundColor: basicColors.lightBlue2,
                          color: basicColors.blueGrayDark,
                          fontSize: '0.75rem',
                          lineHeight: 1.2
                        }
                      : {
                          backgroundColor: basicColors.lightBlue2,
                          color: basicColors.blueGrayDark
                        }}
                  >
                    {cell.value}
                  </Box>
                </Box>
              </TableCell>)
            : (
              <TableCell style={{ padding: 0 }} key={`row-name-${index}-${cellIndex}`} />
              ))}
        </TableRow>
      ))

    return {
      inverter: currentName,
      tableHeader,
      tableRows
    }
  })
}

export const useCreateStringDistributionTable = (
  colsNum = null,
  rows = null,
  currentPanel = null,
  setCurrentPanel = null,
  selectedInverter = null
) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  // console.log(currentPanel)
  const { addedDataloggers, devices, module } = useSelector((state) => state.pvPlantCreation)
  const currentInverter = getDevices({ devices: devices || null, dataloggers: addedDataloggers, type: 'Inverter' }).find(
    (el) => el.uuid === selectedInverter
  )

  if (!(colsNum && rows)) {
    return {
      tableHeader: null,
      tableRow: null
    }
  }
  const addColumn = () => {
    dispatch(handleAddColumn())
  }

  const removeColumn = () => {
    dispatch(handleRemoveColumn())
  }

  const handleRadioChange = (mppt, stringNum) => {
    if (currentPanel) {
      const currentInverter = getDevices({ devices: devices || null, dataloggers: addedDataloggers, type: 'Inverter' }).find(
        (el) => el.uuid === selectedInverter
      )
      const currentInverterIndex = getDevices({
        devices: devices || null,
        dataloggers: addedDataloggers,
        type: 'Inverter'
      }).findIndex((el) => el.uuid === selectedInverter)
      const panelElement = {
        mptt: parseInt(mppt),
        string: stringNum + 1
      }
      if (currentInverter) {
        panelElement.inverter = currentInverter.metadata.inverterNum
          ? currentInverter.metadata.inverterNum
          : currentInverterIndex + 1
      }

      const panel = JSON.parse(JSON.stringify(currentPanel))
      const elIndex = panel.strings.findIndex(
        (el) =>
          el.mptt === parseInt(mppt) && el.string === stringNum + 1 && el.inverter === currentInverter.metadata.inverterNum
      )
      // console.log(panelElement)
      // console.log(elIndex)
      if (elIndex > -1) {
        panel.strings.splice(elIndex, 1)
      } else {
        panel.strings.push(panelElement)
      }
      setCurrentPanel(panel)
    } else {
      return null
    }
  }

  const tableRadioHeader = Array(colsNum + 1)
    .fill('')
    .map((header, index) => {
      if (index === 0) {
        return <TableCell align='center' key={`empty-${index}`} />
      } else {
        return <TableCell align='center' key={`MPPT-${index}`}>{`MPPT${index}`}</TableCell>
      }
    })

  const tableRadioRows = rows.map((row, index) => {
    if (currentPanel) {
      const completeRow = [0].concat(row)
      return (
        <TableRow key={`row-action-${index}`}>
          {completeRow.map((cell, cellIndex) => {
            if (cellIndex === 0) {
              return <TableCell key={`row-name-${index}`} align='center'>{`S${index + 1}`}</TableCell>
            } else {
              const isChecked =
                currentPanel.strings.findIndex(
                  (el) =>
                    el.mptt === cellIndex && el.string === index + 1 && el.inverter === currentInverter.metadata.inverterNum
                ) > -1
              const isDisabled =
                currentPanel.strings.findIndex(
                  (el) =>
                    el.mptt === cellIndex && el.string === index + 1 && el.inverter !== currentInverter.metadata.inverterNum
                ) > -1
              return (
                <TableCell align='center' key={`row-${index}-cell-${cellIndex}`}>
                  <Radio
                    checked={isChecked}
                    disabled={isDisabled}
                    onClick={(e) => handleRadioChange(e.target.value, index)}
                    value={cellIndex}
                  />
                </TableCell>
              )
            }
          })}
        </TableRow>
      )
    } else {
      return null
    }
  })

  const addRow = () => {
    const newRows = Object.assign([], rows)
    newRows.push(Array(colsNum).fill(0))
    dispatch(modifyDistribution({ rows: newRows }))
  }

  const removeRow = () => {
    const newRows = Object.assign([], rows)
    newRows.pop()
    const modulesSum = newRows.reduce((acc, currRow, index) => {
      const rowModuleSum = currRow.reduce((tot, currValue, indexValue) => {
        // console.log('handleCellChange - index: ', index, 'cell Index: ', indexValue)
        tot += Number(currValue)
        return tot
      }, 0)
      acc += rowModuleSum
      return acc
    }, 0)

    // Potenza di picco espressa in kW
    const powerPeak = (Number(module.rows[0].maxPower) * modulesSum) / 1000
    // console.log('removeRow - powerPeak: ', powerPeak)
    dispatch(modifyDistribution({ powerPeak, rows: newRows }))
  }

  const handleCellChange = (value, rowIndex, cellIndex) => {
    dispatch(modifyDistribution({ value, rowIndex, cellIndex }))
    // console.log('handleCellChange - cellValue: ', value, 'row Index: ', rowIndex, 'cell Index: ', cellIndex)
    let modulesSum = rows.reduce((acc, currRow, index) => {
      const rowModuleSum = currRow.reduce((tot, currValue, indexValue) => {
        // console.log('handleCellChange - index: ', index, 'cell Index: ', indexValue)
        if (index === rowIndex) {
          if (indexValue + 1 !== cellIndex) {
            tot += Number(currValue)
          }
        } else {
          tot += Number(currValue)
        }

        return tot
      }, 0)
      acc += rowModuleSum
      return acc
    }, 0)

    modulesSum += Number(value)
    // Potenza di picco modulo espressa in kW
    const powerPeak = (Number(module.rows[0].maxPower) * modulesSum) / 1000

    dispatch(changeDistributionPower(powerPeak))
    // console.log('handleCellChange: - modulesSum', modulesSum)
    // console.log('handleCellChange: - maxPower', module.rows[0].maxPower)
    // console.log('handleCellChange: - powerPeak', powerPeak)
  }

  // table header row (1 empty cell + plus button cell + all MPPT)
  const headerArray = Array(colsNum + 2).fill('')
  const tableHeader = headerArray.map((header, index) => {
    if (index === 0) {
      return <DarkTableCell align='center' key={`empty-${index}`} />
      // if is the last cell is the plus button
    } else if (index === colsNum + 1) {
      return (
        <LightTableCell variant='body' align='center' key={`action-${index}`}>
          <Box display='flex'>
            <Tooltip title='Rimuovi colonna'>
              <span>
                <IconButton disabled={colsNum === 1} className={classes.iconButton} onClick={removeColumn} size='small'>
                  <MinusCircle className={classes.deleteIcon} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title='Aggiungi colonna'>
              <IconButton className={classes.iconButton} onClick={addColumn} size='small'>
                <PlusCircle className={classes.addIcon} />
              </IconButton>
            </Tooltip>
          </Box>
        </LightTableCell>
      )
    } else {
      return <DarkTableCell align='center' key={`MPPT-${index}`}>{`MPPT${index}`}</DarkTableCell>
    }
  })

  const tableRows = rows.concat(['']).map((row, index) => {
    const completeRow = [0].concat(row, [0])
    if (index === rows.length) {
      return (
        <TableRow key={`row-action-${index}`}>
          <LightTableCell>
            <Box display='flex' flexDirection='column'>
              <Tooltip title='Elimina Riga'>
                <span>
                  <IconButton disabled={rows.length === 1} className={classes.iconButton} onClick={removeRow} size='small'>
                    <MinusCircle className={classes.deleteIcon} />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title='Aggiungi riga'>
                <span>
                  <IconButton className={classes.iconButton} onClick={addRow} size='small'>
                    <PlusCircle className={classes.addIcon} />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          </LightTableCell>
        </TableRow>
      )
    } else {
      return (
        <TableRow key={`row-${index}`}>
          {completeRow.map((cell, cellIndex) => {
            if (cellIndex === 0) {
              return <DarkTableCell key={`row-name-${index}`} align='center'>{`S${index + 1}`}</DarkTableCell>
            } else if (cellIndex === completeRow.length - 1) {
              return <LightTableCell align='center' key={`row-action-${index}`} />
            } else {
              return (
                <LightTableCell align='center' key={`row-${index}-cell-${cellIndex}`}>
                  <InputBase
                    classes={{ input: classes.input }}
                    inputProps={{ min: 0, style: { textAlign: 'center' } }}
                    type='number'
                    placeholder='0'
                    onChange={(e) => handleCellChange(e.target.value, index, cellIndex)}
                    value={cell || ''}
                  />
                </LightTableCell>
              )
            }
          })}
        </TableRow>
      )
    }
  })

  return { tableHeader, tableRows, tableRadioRows, tableRadioHeader }
}

// funzione che calcola la superficie corrispondente ad ogni orientamento
// prende in ingresso la superficie del singolo modulo, la distribuzione per ogni inverter, l'array di config dell'oggetto planimetria e un array di orientamenti
// restituisce lo stesso array di orientamenti arricchito dell'attributo surface
export const calculateOrientationsSurfaces = (moduleEfficency, moduleMaxPower, currentDistribution, planimetryConfig, currentOrientations) => {
  const generatorKeyword = 'generator'
  const newOrientations = JSON.parse(JSON.stringify(currentOrientations))

  // Oggetto con chiavi gli uuid degli orientamenti, e con valore un array che contiene tutte le superfici dei generatori
  const surfaces = {}
  // Oggetto con chiavi gli uuid degli orientamenti, e con valore un array che contiene tutte le potenze di picco dei generatori
  const peakPowers = {}
  // Oggetto con chiavi gli uuid degli orientamenti, e con valore il numero di moduli di ogni orientamento
  const orientationsModulesNumber = {}

  // Per ogni orientamento aggiungo una chiave con l'uuid dell'orientamento in surfaces, powerPeaks e orientationsModulesNumber
  newOrientations.forEach(orientation => {
    // Surfaces
    if (Object.keys(surfaces).length > 0) {
      if (surfaces[orientation.uuid] === undefined) {
        surfaces[orientation.uuid] = []
      }
    } else {
      surfaces[orientation.uuid] = []
    }
    // PeakPowers
    if (Object.keys(peakPowers).length > 0) {
      if (peakPowers[orientation.uuid] === undefined) {
        peakPowers[orientation.uuid] = []
      }
    } else {
      peakPowers[orientation.uuid] = []
    }
    // ModulesNumber
    if (Object.keys(orientationsModulesNumber).length > 0) {
      if (orientationsModulesNumber[orientation.uuid] === undefined) {
        orientationsModulesNumber[orientation.uuid] = 0
      }
    } else {
      orientationsModulesNumber[orientation.uuid] = 0
    }
  })

  // prendo la configurazione di ogni generatore
  const generatorsConfig = planimetryConfig.filter(el => el.canvasLibraryType === generatorKeyword)

  // per ogni configurazione di un generatore
  generatorsConfig.forEach(generator => {
    let allDevicesDistribution = []
    // Prendo l'orientamento (uuid) di quel generatore
    const generatorOrientation = (generator.data && generator.data.orientation) || null
    // Per ogni device (inverter) associato al generatore
    generator && generator.devices && generator.devices.forEach(inverter => {
      // console.log('orientations - inverter: ', inverter)
      // Prendo la distribuzione dei moduli per il singolo inverter
      const inverterDistribution = currentDistribution[inverter.deviceId]
      if (inverterDistribution && inverterDistribution !== undefined) {
        if (inverter.additionalData && inverter.additionalData.rows) {
          // Per ogni configurazione delle stringhe di un generatore
          const newDistribution = inverter.additionalData.rows.map((row, index) => {
            // console.log('orientations - row: ', row)
            const newRow = row.map((singleEl) => {
              const rowIndex = Number(singleEl.colIndex) - 1
              // console.log('orientations - singleEl: ', singleEl)
              // console.log('orientations - inverterDistribution: ', inverterDistribution)
              const currentDistributionRow = inverterDistribution.rows[index] && inverterDistribution.rows[index][rowIndex] ? inverterDistribution.rows[index][rowIndex] : undefined
              // prendo ogni valore della distribuzione utilizzato nella configurazione delle stringhe
              // console.log('orientations - currentDistributionRow: ', currentDistributionRow)
              if (currentDistributionRow && currentDistributionRow !== undefined) {
                const currentValue = inverterDistribution.rows[index][(Number(singleEl.colIndex) - 1)] || 0
                // console.log('orientations - currentValue: ', currentValue)
                const newSingleEl = {
                  colIndex: singleEl.colIndex,
                  value: Number(currentValue)
                }

                return newSingleEl
              } else {
                return null
              }
            })

            return newRow.filter(el => el)
          })
          // console.log('orientations - newDistribution: ', newDistribution)
          newDistribution.forEach(el => {
            allDevicesDistribution = allDevicesDistribution.concat(el)
          })
          // console.log('orientations - allDevicesDistribution: ', allDevicesDistribution)
          // console.log('orientations - newDistribution: ', newDistribution)
        }
      }
    })

    if (generatorOrientation) {
      // console.log('orientations - allDevicesDistribution: ', allDevicesDistribution)
      // Sommo tutte le superfici dei generatori sotto un orientamento
      const distributionSurface = allDevicesDistribution.reduce((acc, el) => {
        acc += el.value
        return acc
      }, 0)
      // inserisco all'interno dell'array del singolo orientamento, le superfici ottenute
      // surfaces[generatorOrientation].push(Number(distributionSurface) * Number(moduleSurface))
      if (surfaces[generatorOrientation] && surfaces[generatorOrientation] !== undefined) {
        surfaces[generatorOrientation].push(((Number(distributionSurface) * Number(moduleMaxPower)) / 1000 / Number(moduleEfficency) / 1000) * 100)
      }
      // inserisco all'interno dell'array del singolo orientamento, le potenze di picco in kWp
      if (peakPowers[generatorOrientation] && peakPowers[generatorOrientation] !== undefined) {
        peakPowers[generatorOrientation].push(((Number(distributionSurface) * Number(moduleMaxPower)) / 1000))
      }

      // inserisco all'interno dell'array del singolo orientamento, il numero di moduli di quell'orientamento
      orientationsModulesNumber[generatorOrientation] = distributionSurface
    }
  })

  // Ritorno un array di orientamenti
  const orientations = newOrientations.map(orientation => {
    const orientationSurfaces = surfaces[orientation.uuid]
    const orientationPowerPeaks = peakPowers[orientation.uuid]
    // Per ogni orientamento ritorno il numero totale di moduli
    const modules = orientationsModulesNumber[orientation.uuid]
    // Per ogni orientamento sommo tutte le superfici presenti nell'array corrispondente
    const surface = orientationSurfaces && orientationSurfaces !== undefined ? orientationSurfaces.reduce((acc, el) => acc + el, 0) : 0
    // Per ogni orientamento sommo tutte le potenze di picco presenti nell'array corrispondente
    const peakPower = orientationPowerPeaks && orientationPowerPeaks !== undefined ? orientationPowerPeaks.reduce((acc, el) => acc + el, 0) : 0
    return {
      ...orientation,
      peakPower,
      surface,
      modules
    }
    // console.log('orientations - orientationSurfaces', surface)
  })
  return orientations
  /* console.log('orientations - generators: ', generatorsConfig)
  console.log('orientations - currentDistribution: ', currentDistribution)
  console.log('orientations - newOrientations: ', newOrientations)
  console.log('orientations - surfaces: ', surfaces) */
}
