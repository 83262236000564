import { useEffect, useRef } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import { fabric } from 'fabric'
import log from '@pelv/frontlog'

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    // minHeight: '60vh',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-end'
  }
}))

function Canvas ({
  plantType,
  onPan = null,
  onZoom = null,
  isTransparent = false,
  libraryObjects = {},
  openDeviceDetail,
  canvas,
  assets = [],
  devices = [],
  setCanvas,
  setElement,
  importedCanvas,
  unselectable,
  setEditingObject,
  setSelectedObject,
  setAdditionalData,
  setShowConfigDialog,
  saveCanvas,
  setConfig,
  fieldData,
  config: reduxConfig,
  className,
  generateCanvasObjectFromLibrary,
  setCanvasElements,
  activateTooltip,
  canvasElements = [],
  zoom = null
}) {
  const classes = useStyles()

  const configInstance = JSON.parse(JSON.stringify(reduxConfig))

  const parentRef = useRef(null)
  const config = useRef(configInstance)

  useEffect(() => {
    config.current = configInstance
  })

  const saveListener = () => {
    // console.log('sono dentro saveListener')
    handleSave()
    // rimuovo il listener del pulsante di 'salva'
    if (document.getElementById('save') && document.getElementById('save') !== undefined) {
      document.getElementById('save').removeEventListener('click', () => saveListener(canvas))
    }
    // rimuovo il listener del pulsante di 'salva ed esci'
    /* if (document.getElementById('saveAndExit') && document.getElementById('saveAndExit') !== undefined) {
      document.getElementById('saveAndExit').removeEventListener('click', () => saveListener(canvas))
    }
    // rimuovo il listener del pulsante di 'avanti'
    if (document.getElementById('saveAndNext') && document.getElementById('saveAndNext') !== undefined) {
      document.getElementById('saveAndNext').removeEventListener('click', () => saveListener(canvas))
    }
    // rimuovo il listener del pulsante di 'indietro'
    if (document.getElementById('saveAndBack') && document.getElementById('saveAndBack') !== undefined) {
      document.getElementById('saveAndBack').removeEventListener('click', () => saveListener(canvas))
    } */
  }

  // Funzione che inizializza il canvas
  const initCanvas = () => {
    return new fabric.Canvas('canvas', {
      fireRightClick: true,
      stopContextMenu: true,
      height: parentRef.current.offsetHeight,
      width: parentRef.current.offsetWidth,
      // backgroundColor: '#E8F1F4'
      backgroundColor: isTransparent ? 'transparent' : '#E8F1F4'
    })
  }

  // Funzione che si occupa di renderizzare l'icona di cancellazione dell'elemento
  const renderIcon = (icon) => {
    return function renderIcon (ctx, left, top, styleOverride, fabricObject) {
      // console.log('activeSelection - this: ', this)
      // console.log('activeSelection - fabricObject: ', fabricObject)
      const size = this.cornerSize
      ctx.save()
      ctx.translate(left, top)
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
      ctx.drawImage(icon, -size / 2, -size / 2, size, size)
      ctx.restore()
    }
  }

  // icona svg di edit
  // const editIcon = "data:image/svg+xml,%3C%3Fxml version='1.0'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' version='1.1' width='512' height='512' x='0' y='0' viewBox='0 0 300 300' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath d='M149.996,0C67.157,0,0.001,67.161,0.001,149.997S67.157,300,149.996,300s150.003-67.163,150.003-150.003 S232.835,0,149.996,0z M221.302,107.945l-14.247,14.247l-29.001-28.999l-11.002,11.002l29.001,29.001l-71.132,71.126 l-28.999-28.996L84.92,186.328l28.999,28.999l-7.088,7.088l-0.135-0.135c-0.786,1.294-2.064,2.238-3.582,2.575l-27.043,6.03 c-0.405,0.091-0.817,0.135-1.224,0.135c-1.476,0-2.91-0.581-3.973-1.647c-1.364-1.359-1.932-3.322-1.512-5.203l6.027-27.035 c0.34-1.517,1.286-2.798,2.578-3.582l-0.137-0.137L192.3,78.941c1.678-1.675,4.404-1.675,6.082,0.005l22.922,22.917 C222.982,103.541,222.982,106.267,221.302,107.945z' fill='%231c78bb' data-original='%23000000' style='' class=''/%3E%3C/g%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A"

  // icona svg di cancellazione
  const deleteIcon =
    "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E"

  // icona svg di raggruppamento
  const groupIcon = "data:image/svg+xml,%3Csvg width='450' height='450' viewBox='0 0 450 450' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='225' cy='225' r='225' fill='%23F8FEFF'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M253.141 200.424C224.491 171.047 177.451 170.458 148.075 199.108L87.4237 258.258C58.0471 286.908 57.458 333.948 86.1078 363.324C114.758 392.701 161.797 393.29 191.174 364.64L251.825 305.49C281.201 276.84 281.79 229.8 253.141 200.424ZM226.237 226.871C212.428 212.712 189.754 212.428 175.594 226.237L113.968 286.339C99.8079 300.148 99.5239 322.822 113.333 336.982C127.143 351.142 149.817 351.426 163.976 337.616L225.603 277.514C239.763 263.705 240.047 241.031 226.237 226.871Z' fill='%234AAEE2'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M363.141 87.4237C334.491 58.0472 287.451 57.458 258.075 86.1078L197.424 145.258C168.047 173.908 167.458 220.948 196.108 250.324C224.758 279.701 271.797 280.29 301.174 251.64L361.825 192.49C391.201 163.84 391.79 116.8 363.141 87.4237ZM336.237 113.871C322.428 99.7115 299.754 99.4276 285.594 113.237L223.968 173.339C209.808 187.148 209.524 209.822 223.333 223.982C237.143 238.142 259.817 238.426 273.976 224.616L335.603 164.514C349.763 150.705 350.047 128.031 336.237 113.871Z' fill='%23A3E1FF'/%3E%3Cpath d='M226.237 226.871C214.562 214.901 210.057 193.956 218.396 180.315C204.777 176.839 190.353 177.274 176.916 181.643C173.749 195.343 174.505 209.778 179.207 223.149C193.382 212.566 213.55 213.863 226.237 226.871Z' fill='%234AAEE2'/%3E%3C/svg%3E%0A"

  // elemento img che contiene l'icona di cancellazione dell'oggetto dal canvas
  const deleteImg = document.createElement('img')
  deleteImg.src = deleteIcon

  const groupImg = document.createElement('img')
  groupImg.src = groupIcon

  /*
    Metodo che prende in ingresso una istanza canvas (fabric) e centra l'istanza in relazione a tutti gli elementi disegnati nell'istanza
  */
  /*   function centerCanvas (thisCanvas) {
    if (thisCanvas) {
      const objectPoints = []
      thisCanvas.getObjects().forEach((el) => {
        objectPoints.push(el.getBoundingRect(true, false))
        // console.log('centerCanvas - boundingRect: ', el.getBoundingRect(true, false))
      })
      // console.log('centerCanvas - objectPoints: ', objectPoints)
      if (objectPoints.length > 0) {
        const minX = objectPoints.sort((a, b) => a.left - b.left)[0].left
        const maxX = objectPoints.sort((a, b) => a.left + a.width - (b.left + b.width))[objectPoints.length - 1]
        const minY = objectPoints.sort((a, b) => a.top - b.top)[0].top
        const maxY = objectPoints.sort((a, b) => a.top + a.height - (b.top + b.height))[objectPoints.length - 1]

        // console.log('centerCanvas - da minX a maxY: ', minX, maxX, minY, maxY)

        const selectionRectangle = new fabric.Rect({
          width: maxX.left - minX + maxX.width,
          height: maxY.top - minY + maxY.height,
          top: minY,
          left: minX,
          fill: 'rgba(60,60,60,0.3)'
        })
        // const selectionCenterPoint = selectionRectangle.getCenterPoint()
        const canvasWidth = thisCanvas.getWidth()
        const canvasHeight = thisCanvas.getHeight()

        const centerX = minX - (canvasWidth - selectionRectangle.getScaledWidth()) / 2
        const centerY = minY - (canvasHeight - selectionRectangle.getScaledHeight()) / 2
        // const centerX = (minX - ((canvasWidth - selectionRectangle.width) / 2))
        // const centerY = (minY - ((canvasHeight - selectionRectangle.height) / 2))
        thisCanvas.absolutePan({ x: centerX, y: centerY })

        const heightRatio = canvasHeight / selectionRectangle.getScaledHeight()
        const widthRatio = canvasWidth / selectionRectangle.getScaledWidth()
        const zoomLevel = widthRatio <= heightRatio ? widthRatio : heightRatio
        // imposto lo zoom nel punto centrale, con il livello di zoom calcolato
        // thisCanvas.zoomToPoint(selectionCenterPoint, zoomLevel - 0.1)
        thisCanvas.zoomToPoint({ x: canvasWidth / 2, y: canvasHeight / 2 }, zoomLevel - 0.1)
      }
    }
  } */

  // metodo che setta il componente in modalità visualizzazione e disabilita ogni interazione con esso
  const registerViewMode = () => {
    // console.log('registerViewMode')
    if (canvas) {
      // console.log('registerViewMode with canvas')
      // disabilito tutti gli oggetti del canvas
      canvas.getObjects().forEach((el) => {
        el.set('selectable', false)
      })
      // Prendo tutti gli oggetti del canvas in un' unica selezione
      const selection = new fabric.ActiveSelection(canvas.getObjects())
      // aggiungo la selezione al canvas
      canvas.add(selection)
      // centro la selezione rispetto al canvas
      selection.center()
      // prendo il punto centrale della selezione
      const selectionCenterPoint = selection.getCenterPoint()
      // calcolo la larghezza del canvas
      const canvasWidth = canvas.getWidth()
      // calcolo l'altezza del canvas
      const canvasHeight = canvas.getHeight()
      // calcolo la larghezza della selezione
      const selectionWidth = selection.getScaledWidth()
      // calcolo l'altezza della selezione
      const selectionHeight = selection.getScaledHeight()
      // calcolo il livello di zoom e prendo il valore più basso tra i rapporti delle larghezze e delle altezze
      const heightRatio = canvasHeight / selectionHeight
      const widthRatio = canvasWidth / selectionWidth
      const zoomLevel = widthRatio <= heightRatio ? widthRatio : heightRatio
      // imposto lo zoom nel punto centrale, con il livello di zoom calcolato
      canvas.zoomToPoint(selectionCenterPoint, zoomLevel - 0.1)
      // deseleziono la selezione di oggetti
      canvas.discardActiveObject()

      // rimuovo la selezione fatta
      canvas.remove(selection)

      // canvas.renderAll()
      // imposto il cursore ad arrow quando passo sopra gli oggetti
      canvas.hoverCursor = 'arrow'
      // disabilito la selezione sul canvas
      canvas.selection = false
      canvas.renderAll()
    }
  }

  // Funzione che crea un nuovo oggetto fabric.Control e fa l'override dei controlli di modifica dimensioni dell'oggetto canvas
  const overrideControls = () => {
    // Aggiungo il pulsante di cancellazione ai controlli dell'oggetto canvas
    fabric.Object.prototype.controls.delete = new fabric.Control({
      x: 0.5,
      y: -0.5,
      offsetY: -16,
      cursorStyle: 'pointer',
      // funzione che si attiva al click dell'icona di cancellazione dell'oggetto dal canvas
      mouseUpHandler: (eventData, { target }) => {
        // se sto eliminando un oggetto
        if (target.id) {
          // cerca il riferimento dell'oggetto nell'array di configurazione, se lo trova lo elimina
          const newConfig = config.current.filter((el) => el.id !== target.id)
          // setta la nuova configurazione
          setConfig(newConfig)
          // elimina l'oggetto dal canvas
          canvas.remove(target)
          // se sto eliminando una selezione di oggetti
        } else {
          // salvo la configurazione corrente
          let currentConfig = JSON.parse(JSON.stringify(config.current))
          if (target && target._objects) {
            // Per ogni oggetto
            target._objects.forEach((el) => {
              // Elimino la configurazione da quella corrente
              currentConfig = currentConfig.filter((configEl) => configEl.id !== el.id)
              // Rimuovo l'oggetto dal canvas
              canvas.remove(el)
            })
          }
          // Setto la nuova configurazione
          setConfig(currentConfig)
          // rimuovo la selezione
          canvas.discardActiveObject()
        }
      },
      render: renderIcon(deleteImg),
      cornerSize: 24
    })

    // console.log('activeSection - prototype: ', fabric.Object.prototype)
    if (plantType === 'led') {
      fabric.Object.prototype.controls.group = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: -16,
        offsetX: -26,
        cursorStyle: 'pointer',
        mouseUpHandler: (eventData, target) => {
          const currentGroupId = target.groupId
          if (currentGroupId) {
            const groupElements = canvas.getObjects().filter(el => (el.groupId && el.groupId === currentGroupId))
            const newGroup = new fabric.Group(groupElements)
            newGroup.id = currentGroupId
            groupElements.forEach(el => {
              canvas.remove(el)
            })
            newGroup.setControlVisible('group', false)
            canvas.add(newGroup)
            canvas.renderAll()
          }
        },
        render: renderIcon(groupImg),
        cornerSize: 24
      })
    }
  }

  // Metodo che registra il listener dello scroll del mouse per attivare lo zoom del canvas
  const registerZoomListener = () => {
    if (canvas) {
      // listener sul mouseWheel, che in base allo scorrimento
      // della rotella del mouse setta lo zoom del canvas in base alla posizione del cursore
      canvas.on('mouse:wheel', (opt) => {
        const delta = opt.e.deltaY
        const zoom = canvas.getZoom()
        let updatedZoom = zoom * 0.999 ** delta
        if (updatedZoom > 20) updatedZoom = 20
        if (updatedZoom < 0.01) updatedZoom = 0.01
        canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, updatedZoom)
        if (onZoom) {
          onZoom(updatedZoom)
        }
        opt.e.preventDefault()
        opt.e.stopPropagation()
      })
    }
  }

  const registerPanListener = () => {
    let panning = false

    if (canvas) {
      // listener sul mouseDown che in caso di pressione di uno dei tasti ALT della tastiera setta la variabile di modalità pan a true
      canvas.on('mouse:down', (event) => {
        if (event.e.altKey) {
          panning = true
          canvas.setCursor('move')
          canvas.discardActiveObject()
        }
      })
      // listener sul mouseMove che al movimento del mouse, se variabile di modalità pan è true:
      // 1. disbilita la selezione
      // 2. crea un punto partendo dalle coordinate di movimento del mouse
      // 2. setta il pan del canvas a quel punto
      canvas.on('mouse:move', (mouseEvent) => {
        if (panning && mouseEvent && mouseEvent.e) {
          canvas.selection = false
          const delta = new fabric.Point(mouseEvent.e.movementX, mouseEvent.e.movementY)
          canvas.relativePan(delta)
          if (onPan) {
            onPan([mouseEvent.e.movementX, mouseEvent.e.movementY])
          }
        }
      })

      // listener sul mouseUp che setta la variabile di modalità pan a false e riabilita la selezione
      canvas.on('mouse:up', () => {
        panning = false
        canvas.selection = true
      })
    }
  }

  /*   useEffect(() => {
    if (zoom && canvas) {
      let updatedZoom = Number(Number(zoom / 20).toFixed(2))
      console.log('getZoom: ', canvas.getZoom())
      console.log('zoom: ', zoom)
      console.log('updatedzoom: ', updatedZoom)
      if (updatedZoom < 0.01) updatedZoom = 0.01
      canvas.setZoom(updatedZoom)
    }
  }, [zoom]) */

  useEffect(() => {
    if (parentRef) {
      setCanvas(initCanvas())
    }
  }, [setCanvas])

  useEffect(() => {
    if (canvasElements && canvas) {
      overrideControls()
      log({ text: 'canvasElements => ', variable: canvasElements, tag: 'canvas' })

      canvasElements.forEach((el) => {
        el.setControlVisible('group', false)
        canvas.add(el)
      })

      // seleziono l'ultimo elemento inserito se c'è almeno un elemento sul canvas e un solo elemento nella lista di canvasElements
      if (canvas.getObjects().length > 0 && canvas.getObjects().length === 1) {
        // deseleziono tutto ciò che era selezionato in precedenza sul canvas
        canvas.discardActiveObject()
        canvasElements[canvasElements.length - 1].selectable = false
        if (canvasElements[canvasElements.length - 1]?.selectable) {
          canvas.setActiveObject(canvasElements[canvasElements.length - 1])
        }
      }
    }
    // eslint-disable-next-line
  }, [canvasElements])

  // funzione che si attiva ogni volta che cambia la configurazione
  // il suo scopo è quello di aggiornare gli oggetti di canvas in seguito alla modifica delle loro configurazioni specifiche
  useEffect(() => {
    if (canvas && !unselectable) {
      canvas.getObjects().forEach((el) => {
        if (Object.prototype.hasOwnProperty.call(el, 'text')) {
          const currentConfig = config.current.find((configEl) => configEl.id === el.id)
          if (currentConfig && currentConfig.data && currentConfig.data.draw) {
            if (currentConfig.data.draw.currentText) {
              el.set('text', currentConfig.data.draw.currentText)
            }
            if (currentConfig.data.draw.backgroundColor) {
              el.set('backgroundColor', currentConfig.data.draw.backgroundColor)
            }
            canvas.renderAll()
          }
        } else if (Object.prototype.hasOwnProperty.call(el, '_objects')) {
          if (el._objects.length === 2) {
            const currentConfig = config.current.find((configEl) => configEl.id === el.id)
            if (currentConfig && currentConfig.data && currentConfig.data.draw) {
              el._objects.forEach((obj) => {
                if (Object.prototype.hasOwnProperty.call(obj, 'fill') && (obj.type === 'rect' || obj.type === 'circle')) {
                  if (currentConfig.data.draw.backgroundColor) {
                    obj.set('fill', `${currentConfig.data.draw.backgroundColor}`)
                    canvas.renderAll()
                  }
                }
                if (Object.prototype.hasOwnProperty.call(obj, 'stroke') && obj.type === 'rect') {
                  if (currentConfig.data.draw.borderColor) {
                    obj.set('stroke', currentConfig.data.draw.borderColor)
                    canvas.renderAll()
                  }
                }
                if (Object.prototype.hasOwnProperty.call(obj, 'text')) {
                  if (currentConfig.data.draw.number) {
                    obj.set('text', `${obj.text[0]}${currentConfig.data.draw.number}`)
                    canvas.renderAll()
                  }
                  if (currentConfig.data.draw.currentText) {
                    obj.set('text', currentConfig.data.draw.currentText)
                    canvas.renderAll()
                  }
                }
              })
            }
          } else {
            const currentConfig = config.current.find((configEl) => configEl.id === el.id)
            if (currentConfig && (currentConfig.canvasLibraryType === 'lightPoint' || currentConfig.canvasLibraryType === 'electricLine')) {
              el._objects.forEach((obj, index) => {
                if (Object.prototype.hasOwnProperty.call(obj, 'fill') && obj.type === 'rect') {
                  if (currentConfig.data && currentConfig.data.draw && currentConfig.data.draw.backgroundColor) {
                    obj.set('fill', `${currentConfig.data.draw.backgroundColor}`)
                    canvas.renderAll()
                  }
                }
                if (Object.prototype.hasOwnProperty.call(obj, 'text')) {
                  // console.log('currentConfig: ', currentConfig)
                  if (currentConfig.data && currentConfig.data.line && currentConfig.data.line.name) {
                    obj.set('text', currentConfig.data.line.name)
                    canvas.renderAll()
                  }
                }
                if (index === 2 && Object.prototype.hasOwnProperty.call(obj, 'text')) {
                  if (currentConfig.data && currentConfig.data.power) {
                    obj.set('text', `${currentConfig.data.power} W`)
                    canvas.renderAll()
                  }
                }
                if (index === 3 && Object.prototype.hasOwnProperty.call(obj, 'text')) {
                  if (currentConfig.data && currentConfig.data.lightsNum) {
                    obj.set('text', `P.Luce: ${currentConfig.data.lightsNum}`)
                    canvas.renderAll()
                  }
                }
              })
            }
            if (currentConfig?.canvasLibraryType === 'sensor') {
              const filteredProperties = currentConfig?.additionalData?.properties.filter(prop => prop.propKey !== 'adc' && currentConfig.additionalData.selectedProperties.includes(prop.propKey))
              // Variabile che si aggiorna tenendo conto che una riga di infobox è composta da 2 elementi di tipo testo e che il primo elemento di un infobox è un rettangolo
              let propsIndex = 0
              el._objects.forEach((obj, index) => {
                if (Object.prototype.hasOwnProperty.call(obj, 'fill') && obj.type === 'rect') {
                  if (currentConfig.data.draw.backgroundColor) {
                    obj.set('fill', `${currentConfig.data.draw.backgroundColor}`)
                    canvas.renderAll()
                  }
                } else if (Object.prototype.hasOwnProperty.call(obj, 'text') && index === 1) {
                  const assetName = currentConfig?.data?.draw?.name ?? ''
                  const backgroundObj = el._objects[0] ?? null
                  obj.set('text', assetName)
                  if (backgroundObj?.width < obj?.width) {
                    backgroundObj.set('width', obj?.width + 2)
                    el.set('width', obj?.width + 2)
                  } else if (obj?.width < 120) {
                    backgroundObj.set('width', 120)
                    el.set('width', 120)
                  }
                  canvas.renderAll()
                } else if (Object.prototype.hasOwnProperty.call(obj, 'text') && obj.originX === 'left') {
                  if (propsIndex > -1) {
                    const propName = filteredProperties?.[propsIndex]?.label ?? ''
                    obj.set('text', propName)
                    canvas.renderAll()
                  }
                } else if (Object.prototype.hasOwnProperty.call(obj, 'text') && obj.originX === 'right') {
                  if (propsIndex > -1) {
                    const propValue = filteredProperties?.[propsIndex]?.value ?? ''
                    obj.set('text', `${propValue}`)
                    canvas.renderAll()
                  }
                }
                // Se l'elemento del gruppo non è il primo ed ha indice pari, allora devo fare riferimento alla prossima proprietà
                if (index > 1 && index % 2 !== 0) {
                  propsIndex += 1
                }
              })
            }
            if (currentConfig && currentConfig.data && currentConfig.data.draw) {
              if (currentConfig && currentConfig.data && currentConfig.data.draw) {
                el._objects.forEach((obj) => {
                  if (Object.prototype.hasOwnProperty.call(obj, 'fill') && obj.type === 'rect') {
                    if (currentConfig.data.draw.backgroundColor) {
                      obj.set('fill', `${currentConfig.data.draw.backgroundColor}`)
                      canvas.renderAll()
                    }
                  }
                  /* if (Object.prototype.hasOwnProperty.call(obj, 'stroke') && obj.type === 'rect') {
                    if (currentConfig.data.draw.backgroundColor) {
                      obj.set('stroke', `${currentConfig.data.draw.backgroundColor}`)
                      canvas.renderAll()
                    }
                  } */
                })
              }
            }
          }
        }
      })
    }
  })

  // Metodo che si occupa di impostare le info per mostrare la dialog di configurazione pre compilata con i dati inseriti in fase di creazione
  const handleModifyDialog = (currentObject) => {
    // console.log(currentObject)
    // prendo la configurazione dell'oggetto corrente
    const objectConfig = config.current.find((el) => el.id === currentObject.id)
    // console.log('objectConfig: ', objectConfig)
    // se la configurazione esiste
    if (objectConfig) {
      // prendo il template dell'oggetto di libreria
      const getLibraryObject = libraryObjects[objectConfig.canvasLibraryType]
      // riempio i deviceIds per la select della dialog
      // const objectsIds = objectConfig.devices.map(device => device.deviceId)
      setSelectedObject(getLibraryObject)
      // setto l'oggetto specifico di config
      setElement({ config: [{ ...objectConfig }] })
      // setDeviceIds(objectsIds)
      // aggiungo eventuali dati addizionali di configurazione specifica
      setAdditionalData(objectConfig.data)
      // mostro la dialog di configurazione
      setShowConfigDialog(true)
      // setto l'oggetto che intendo modificare
      setEditingObject(currentObject)
    }
  }

  // Metodo che legge e interpreta il JSON della props 1, lo disegna sul canvas e ne carica in memoria le configurazioni specifiche
  useEffect(() => {
    /* if (canvas) {
      canvas.clear()
      canvas.setBackgroundColor('#E8F1F4')
      canvas.setZoom(1)
    } */
    const response = importedCanvas
    log({ text: 'importedCanvas => ', variable: importedCanvas, tag: 'canvas' })
    const canvasObjectsConfig = response.draw || []
    const dataObjectsConfig = response.config || []
    const objectsList = canvasObjectsConfig.map((el) => generateCanvasObjectFromLibrary(el, true))
    setCanvasElements(objectsList)
    setConfig(dataObjectsConfig)

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (Object.keys(fieldData).length > 0) {
      // console.log('setField useEffect: ', config)
      if (canvas) {
        // console.log('canvasObjects: ', canvas.getObjects())
        // memorizzo i dati del campo all'interno della struttura config
        for (const s in fieldData) {
          const uuid = s
          const thisDevice = fieldData[uuid]
          for (let c = 0; c < config.current.length; c++) {
            if (config?.current?.[c]?.devices?.length > 0) {
              for (let n = 0; n < config.current[c].devices.length; n++) {
                if (config.current[c].devices[n].deviceId === uuid) {
                  for (const m in thisDevice) {
                    if (config.current[c].devices[n].properties[m]) {
                      config.current[c].devices[n].properties[m].value = thisDevice[m]
                    }
                  }
                }
              }
            } else if (config?.current?.[c]?.assets?.length > 0) {
              for (let n = 0; n < config.current[c].assets.length; n++) {
                const currentAsset = assets.find(asset => config.current[c].assets[n].assetId === asset.uuid)
                if (currentAsset.propertyMappings?.adc?.resourceId === uuid) {
                  const currentDevice = devices.find(device => device.uuid === uuid)
                  for (const m in thisDevice) {
                    if (config.current[c].additionalData?.selectedProperties?.includes(m)) {
                      const currentProp = config.current[c].additionalData?.properties.find(prop => prop.propKey === m)
                      if (currentProp) {
                        const uom = currentDevice?.deviceType?.properties?.[m]?.uom || ''
                        currentProp.value = `${thisDevice[m]} ${uom}`
                        const newProps = [...config.current[c].additionalData?.properties.filter(prop => prop.propKey !== m), currentProp]
                        config.current[c].additionalData.properties = newProps
                      }
                    }
                  }
                }
              }
            }
          }
        }
        // chiamo la receive
        for (const s in fieldData) {
          // console.log('cerco la receive di questo uuid: ', s)
          const uuid = s

          // console.log('prima della receive config: ', config)
          for (let c = 0; c < config.current.length; c++) {
            if (config.current[c].devices && config.current[c].devices !== undefined && config.current[c].devices.length > 0) {
              for (let n = 0; n < config.current[c].devices.length; n++) {
                if (config.current[c].devices[n].deviceId === uuid) {
                  const canvasObjects = canvas.getObjects()
                  for (let l = 0; l < canvasObjects.length; l++) {
                    if (canvasObjects[l].id === config.current[c].id) {
                      // console.log('l: ', l)
                      const elementType =
                        config.current[c].canvasLibraryType === 'infobox'
                          ? 'infobox'
                          : config.current[c].canvasLibraryType === 'generator'
                            ? 'generator'
                            : config.current[c].devices[n].type
                      /* console.log('receive - elementType: ', elementType)
                      console.log('receive - canvasLibraryType: ', config.current[c].canvasLibraryType)
                      console.log('receive - type: ', config.current[c].devices[n].type) */
                      const newElStructure = libraryObjects[elementType].onReceive(
                        fabricConvertToApio([canvasObjects[l]])[0],
                        config.current[c]
                      )
                      // console.log('neElStructure: ', newElStructure)
                      if (newElStructure && Object.keys(newElStructure).length > 0) {
                        const newEl = generateCanvasObjectFromLibrary({ draw: newElStructure })
                        canvas.remove(canvasObjects[l])
                        // console.log('newEl - onReceive: ', newEl)
                        if (unselectable) {
                          newEl.set('selectable', false)
                        }
                        canvas.add(newEl)
                      }
                    }
                  }
                }
              }
            } else if (config.current[c].assets && config.current[c].assets !== undefined && config.current[c].assets.length > 0) {
              for (let n = 0; n < config.current[c].assets.length; n++) {
                const currentAsset = assets.find(asset => config.current[c].assets[n].assetId === asset.uuid)
                if (currentAsset.propertyMappings?.adc?.resourceId === uuid) {
                  const canvasObjects = canvas.getObjects()
                  for (let l = 0; l < canvasObjects.length; l++) {
                    if (canvasObjects[l].id === config.current[c].id) {
                      const elementType = config.current[c].canvasLibraryType
                      const newElStructure = libraryObjects[elementType].onReceive(
                        fabricConvertToApio([canvasObjects[l]])[0],
                        config.current[c]
                      )
                      if (newElStructure && Object.keys(newElStructure).length > 0) {
                        const newEl = generateCanvasObjectFromLibrary({ draw: newElStructure })
                        canvas.remove(canvasObjects[l])
                        if (unselectable) {
                          newEl.set('selectable', false)
                        }
                        canvas.add(newEl)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (unselectable) {
        registerViewMode()
      }
    }
    // eslint-disable-next-line
  }, [fieldData])

  useEffect(() => {
    // Al click del tasto destro sull'oggetto si apre la sua modal di configurazione
    if (!unselectable && canvas) {
      canvas.on('mouse:down', (event) => {
        // Tasto destro
        if (event.button === 3) {
          if (event.target) {
            handleModifyDialog(event.target)
          }
        }
      })
    }

    if (!unselectable && canvas && plantType === 'led') {
      canvas.on('selection:created', event => {
        const { selected } = event
        // if (selected.length > 1 && selected.some(obj => (!obj.groupId && obj.groupId === undefined))) {
        if (selected.length > 1) {
          if (event.target) {
            event.target.setControlVisible('group', false)
          }
        }
        log({ text: 'activeSection - selection - event => ', variable: event, tag: 'canvas' })
      })
      canvas.on('mouse:dblclick', event => {
        log({ text: 'activeSelection - event.target => ', variable: event, tag: 'canvas' })
        // Se facciamo doppio click su un gruppo
        if (event.target && event.target.type === 'group') {
          // Prendiamo gli oggetti del gruppo
          const activeGroup = event.target.getObjects()
          // trasformiamo il gruppo in una selezione di singoli elementi (eliminando il gruppo precedente dal canvas)
          canvas.getActiveObject().toActiveSelection()
          // Renderizziamo il canvas se c'è necessità di farlo
          canvas.requestRenderAll()
          // deselezioniamo tutto quello che era selezionato sul canvas
          canvas.discardActiveObject()
          log({ text: 'activeSelection - activeGroup => ', variable: activeGroup, tag: 'canvas' })
          // Scorriamo gli oggetti del gruppo, se troviamo un rettangolo (forse da aggiungere il cerchio)
          activeGroup.forEach(obj => {
            log({ text: 'activeSelection - signleObj => ', variable: obj, tag: 'canvas' })
            if (obj.type === 'rect') {
              canvas.setActiveObject(obj)
            }
          })
          // Rifaccio il render del canvas
          canvas.renderAll()
        }
      })
    }

    if (unselectable && canvas) {
      canvas.on('mouse:over', (event) => {
        if (event.target) {
          activateTooltip(event)
        }
      })

      canvas.on('mouse:up', (event) => {
        if (event.target && openDeviceDetail) {
          openDeviceDetail(event.target.id)
        }
      })
    }

    // eslint-disable-next-line
  }, [canvas, unselectable])

  // Metodo che traduce gli oggetti del canvas in oggetti di libreria
  // Prende in ingresso un array che contiene gli oggetti del canvas
  // Restituisce un array di oggetti tradotti in oggetti di libreria
  const fabricConvertToApio = (elements) => {
    // console.log('fabricConvertToApio: ', elements)
    return elements
      .map((el) => {
        // se è un rettangolo
        const coords = el.getPointByOrigin('left', 'top')
        if (el.isType('rect')) {
          // const coords = el.getCoords()
          // console.log('fabricConvertToApio - coords: ', coords)
          // console.log('fabricConvertToApio - pointByOrigin: ', el.getPointByOrigin('left', 'top'))
          // const leftPoint = coords[0]
          return {
            id: el.id,
            type: 'rect',
            fill: el.fill,
            top: coords.y,
            left: coords.x,
            width: el.getScaledWidth() - 1,
            // width: (el.aCoords.tr.x - el.aCoords.bl.x) - 1,
            // height: (el.aCoords.bl.y - el.aCoords.tr.y) - 1,
            height: el.getScaledHeight() - 1,
            angle: el.angle
          }
        } else if (el.isType('triangle')) {
          // const coords = el.getCoords()
          // const leftPoint = coords[0]
          return {
            id: el.id,
            type: 'triangle',
            fill: el.fill,
            top: coords.y,
            left: coords.x,
            width: el.getScaledWidth() - 1,
            height: el.getScaledHeight() - 1,
            // width: (el.aCoords.tr.x - el.aCoords.bl.x) - 1,
            // height: (el.aCoords.bl.y - el.aCoords.tr.y) - 1,
            angle: el.angle
          }
        } else if (el.isType('group')) {
          // const coords = el.getCoords()
          // const leftPoint = coords[0]
          const objects = el._objects.map((obj) => {
            const singleObj = {
              type: obj.type,
              angle: obj.angle,
              fontSize: obj.fontSize,
              textAlign: obj.textAlign,
              top: obj.top,
              left: obj.left
            }
            if (obj.groupId) {
              singleObj.groupId = obj.groupId
            }
            if (obj.originX) {
              singleObj.originX = obj.originX
            }
            if (obj.originY) {
              singleObj.originY = obj.originY
            }
            if (obj.radius) {
              singleObj.radius = obj.radius
            }
            if (obj.fill) {
              singleObj.fill = obj.fill
            }
            if (obj.stroke) {
              singleObj.stroke = obj.stroke
            }
            if (obj.strokeLinejoin) {
              singleObj.strokeLinejoin = obj.strokeLinejoin
            }
            if (obj.strokeWidth) {
              singleObj.strokeWidth = obj.strokeWidth
            }
            if (obj.rx) {
              singleObj.rx = obj.rx
            }
            if (obj.text) {
              singleObj.text = obj.text
            }
            if (obj.path) {
              singleObj.path = obj.path
            }
            if (obj.width) {
              singleObj.width = obj.width
            }
            if (obj.height) {
              singleObj.height = obj.height
            }
            if (obj.scaleX) {
              singleObj.scaleX = obj.scaleX
            }
            if (obj.scaleY) {
              singleObj.scaleY = obj.scaleY
            }
            return singleObj
          })

          return {
            id: el.id,
            type: 'group',
            angle: el.angle,
            left: coords.x,
            top: coords.y,
            scaleX: el.getObjectScaling().scaleX,
            scaleY: el.getObjectScaling().scaleY,
            objects
          }
          // se è un testo
        } else if (el.isType('text')) {
          // const coords = el.getCoords()
          // const leftPoint = coords[0]
          const textObj = {
            id: el.id,
            type: 'text',
            text: el.text,
            fill: el.fill,
            top: coords.y,
            left: coords.x,
            angle: el.angle,
            scaleX: el.getObjectScaling().scaleX,
            scaleY: el.getObjectScaling().scaleY
          }
          if (el.backgroundColor) {
            textObj.backgroundColor = el.backgroundColor
          }
          return textObj
        } else {
          return null
        }
      })
      .filter((el) => el)
  }

  // Metodo che salva gli lo stato corrente del canvas e le configurazioni specifiche per ogni oggetto
  const handleSave = () => {
    // scarto una possibile selezione in corso all'atto del save
    canvas.discardActiveObject()
    // canvas.setZoom(1)
    canvas.renderAll()
    const canvasState = canvas.getObjects()
    const canvasObejctsConfig = fabricConvertToApio(canvasState)
    // console.log('canvasObjectsConfig: ', JSON.stringify(canvasObejctsConfig))
    const response = {
      draw: canvasObejctsConfig,
      config: config.current
    }
    if (saveCanvas) {
      saveCanvas(response)
    }
    // console.log('generator: ', JSON.stringify(response))
  }

  useEffect(() => {
    if (canvas && !unselectable) {
      return () => {
        // console.log('sono qui nel return')
        // console.log('config in return useEffect: ', config.current)
        // console.log('component unmount')
        // riabilito il click del tasto destro
        window.removeEventListener('contextmenu', (e) => e.preventDefault())
        handleSave()
      }
    } else if (canvas) {
      return () => {
        // console.log('sono qui nel return di view')
      }
    }
    // eslint-disable-next-line
  }, [canvas, unselectable])

  useEffect(() => {
    if (canvas && !unselectable) {
      // disabilito il tasto destro sul canvas e sugli oggetti contenuti
      window.addEventListener('contextmenu', (e) => e.preventDefault())
      // setto il listener del pulsante di 'salva ed esci'
      // document.getElementById('saveAndExit').addEventListener('click', () => saveListener(canvas))
      document.getElementById('save').addEventListener('click', () => saveListener(canvas))
      registerZoomListener()
      registerPanListener()
      // centerCanvas(canvas)
      // setto il listener del pulsante di 'avanti'
      /*  document.getElementById('saveAndNext').addEventListener('click', () => saveListener(canvas))
      // setto il listener del pulsante di 'indietro'
      document.getElementById('saveAndBack').addEventListener('click', () => saveListener(canvas)) */
      // console.log('listener Istanziati')
    } else {
      registerViewMode()
    }
    // eslint-disable-next-line
  }, [canvas])

  return (
    <div ref={parentRef} className={clsx(classes.root, className)}>
      <canvas id='canvas' />
    </div>
  )
}
export default Canvas
