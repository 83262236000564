import { memo } from 'react'

const logo = '/static/logo.svg'
const whiteLogo = '/static/logo_bianco.svg'
const blackLogo = '/static/logo_nero.svg'
const shortLogo = '/static/marchio.svg'
const shortWhiteLogo = '/static/marchio_bianco.svg'
const shortBlackLogo = '/static/marchio_nero.svg'

const geLogo = '/static/ge.png'
const geLogoFull = '/static/ge_bianco.png'

const Logo = ({ isGe = false, short = false, logocolor = 'default', ...rest }) => {
  let logoUrl = logo
  if (isGe) {
    logoUrl = short ? geLogo : geLogoFull
  } else if (short) {
    logoUrl = logocolor === 'white' ? shortWhiteLogo : logocolor === 'black' ? shortBlackLogo : shortLogo
  } else {
    logoUrl = logocolor === 'white' ? whiteLogo : logocolor === 'black' ? blackLogo : logo
  }

  return (
    <img
      alt='Logo'
      height='auto'
      width='auto'
      src={logoUrl}
      {...rest}
    />
  )
}

export default memo(Logo)
