/* eslint-disable no-use-before-define */
import { useState, useEffect, memo, Fragment } from 'react'
import { useLocation, Link as RouterLink } from 'react-router-dom'
// import PropTypes from 'prop-types'
import { canIAccessThisUrl } from '@/hooks/usePermissions'
import {
  // Box,
  AppBar,
  Toolbar,
  Divider,
  Drawer,
  // Avatar,
  // Typography,
  // useTheme,
  useMediaQuery,
  makeStyles
} from '@material-ui/core'
import Logo from '@/components/Logo'
import useAuth from '@/hooks/useAuth'
import NavItem from './NavItem'
import MobileNavItem from './MobileNavItem'
import Settings from './Settings'
import log from '@pelv/frontlog'

import {
  // ArrowDropDown as MoreInfoIcon,
  HomeWorkTwoTone as PlantsIcon,
  BusinessTwoTone as PlantIcon,
  AppsTwoTone as OverviewIcon,
  // ViewCarouselTwoTone as OverviewIcon,
  SettingsTwoTone as SettingsIcon,
  // BuildTwoTone as ManagmentIcon,
  ChromeReaderModeTwoTone as ManagmentIcon,
  AssessmentTwoTone as AnalyticsIcon,
  ReportTwoTone as AnomaliesIcon,
  BookTwoTone as ReportsIcon,
  PlaceTwoTone as MapIcon,
  // DevicesOtherTwoTone as DeviceIcon,
  // NfcTwoTone as DeviceIcon,
  DeveloperBoardTwoTone as DeviceIcon,
  AssignmentIndTwoTone as RolesIcon,
  AccountCircleTwoTone as UserIcon,
  PeopleTwoTone as UsersIcon,
  GroupWorkTwoTone as GroupsIcon
} from '@material-ui/icons'
import { configuration } from '@/config'

export const routeSections = [
  {
    title: 'Panoramica',
    UrlIcon: OverviewIcon,
    href: '/app/overview',
    showOnMenu: true,
    items: [
      {
        title: 'Mappa',
        href: '/app/overview/maps',
        showOnMenu: true,
        UrlIcon: MapIcon,
        shortView: null
      },
      {
        title: 'Analytics',
        href: '/app/overview/analytics',
        showOnMenu: true,
        UrlIcon: AnalyticsIcon,
        shortView: null
      },
      {
        title: 'Anomalie',
        href: '/app/overview/anomalies',
        showOnMenu: true,
        UrlIcon: AnomaliesIcon,
        shortView: null
      },
      {
        title: 'Reports',
        href: '/app/overview/reports',
        showOnMenu: true,
        UrlIcon: ReportsIcon,
        shortView: null
      },
      {
        title: 'Impianto',
        href: '/app/view',
        showOnMenu: false,
        UrlIcon: PlantIcon,
        shortView: null
      }
    ]
  },
  {
    title: 'Gestione',
    UrlIcon: ManagmentIcon,
    href: '/app/management',
    showOnMenu: true,
    items: [
      {
        title: 'Datalogger',
        href: '/app/management/devices',
        showOnMenu: true,
        UrlIcon: DeviceIcon,
        shortView: null
      },
      {
        title: 'Impianti',
        href: '/app/management/plants',
        showOnMenu: true,
        UrlIcon: PlantsIcon,
        shortView: null
      },
      {
        title: 'Utenti',
        href: '/app/management/users',
        showOnMenu: true,
        UrlIcon: UsersIcon,
        shortView: null
      },
      {
        title: 'Ruoli',
        href: '/app/management/roles',
        showOnMenu: true,
        UrlIcon: RolesIcon,
        shortView: null
      },
      {
        title: 'Gruppi',
        href: '/app/management/groups',
        showOnMenu: true,
        UrlIcon: GroupsIcon,
        shortView: null
      }
    ]
  },
  {
    title: 'Impostazioni',
    UrlIcon: SettingsIcon,
    href: '/app/settings',
    showOnMenu: false,
    items: [
      {
        title: 'Account',
        href: '/app/settings/account',
        showOnMenu: false,
        UrlIcon: UserIcon,
        shortView: null
      }
    ]
  }
]

const useStyles = makeStyles((theme) => ({
  desktopDrawer: {
    width: 53,
    height: '100%',
    paddingTop: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: theme.palette.background.paper,
    '@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none)': {
      // backdropFilter: 'saturate(180%) blur(20px)', // dal sito Apple
      backdropFilter: 'saturate(100%) blur(15px)',
      '-webkit-backdrop-filter': 'saturate(100%) blur(15px)',
      backgroundColor: 'rgba(38, 61, 84, 0.4)'
    },
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  desktopDrawerPaper: {
    width: 53,
    paddingTop: theme.spacing(2),
    textAlign: 'center'
  },
  mobileNav: {
    top: 'auto',
    left: '3%',
    bottom: '1.5%',
    width: '94%',
    borderRadius: 40,
    zIndex: 99999
  },
  mobileNavPaper: {
    backgroundColor: theme.palette.background.dark,
    '@supports (backdrop-filter: none) or (-webkit-backdrop-filter: none)': {
      '-webkit-backdrop-filter': 'saturate(98%) blur(14px)',
      backdropFilter: 'saturate(98%) blur(14px)',
      backgroundColor: 'rgba(24, 38, 56, 0.3)',
      borderRadius: 40
    }
  },
  grow: {
    flexGrow: 1
  },
  divider: {
    marginTop: theme.spacing(1) - 4,
    marginBottom: theme.spacing(1) - 4
  },
  hiddenDivider: {
    marginTop: theme.spacing(1) - 4,
    marginBottom: theme.spacing(1) - 4,
    backgroundColor: 'transparent'
  },
  logoOnButton: {
    width: 'auto',
    height: 28
    // marginRight: 16
  }
}))

const NavBar = () => {
  const classes = useStyles()
  const location = useLocation()
  // const theme = useTheme()
  const { user, currentProject } = useAuth()
  const [navSections, setNavSections] = useState([])
  // log({ text: 'location.pathname', variable: location.pathname, tag: 'navbar' })
  // log({ text: 'routeSections => ', variable: routeSections, tag: 'navbar' })
  // const isExtraSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'))
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  // mi sistemo i dati
  useEffect(() => {
    // nav
    let temp = []
    const final = []
    // mi setto la variabile per la nav
    routeSections.forEach(section => {
      const thisGroup = {
        title: section.title,
        href: section.href,
        UrlIcon: section.UrlIcon,
        showOnMenu: section.showOnMenu
      }
      temp = section.items.filter(route => {
        // controllo se posso accedere all'url
        const thisAnswer = canIAccessThisUrl(user?.appPermissions || [], route.href, user?.groups || [])
        return thisAnswer.answer && route.showOnMenu
      })
      if (temp.length > 0) {
        final.push({ ...thisGroup, items: temp })
        // final.push(...temp)
        temp = []
      }
    })
    setNavSections(final)
    // log({ text: 'temp => ', variable: temp, tag: 'navbar' })
    log({ text: 'navSections => ', variable: final, tag: 'navbar' })
  }, [location.pathname])

  const isGeDashboard = configuration.geProject === currentProject?.uuid
  const hasProjects = user.projects && user.projects.length > 0

  return (
    <>
      {!isSmall
        ? (
          <Drawer
            elevation={4}
            variant='permanent'
            className={classes.desktopDrawer}
            classes={{ paper: classes.desktopDrawerPaper }}
          >
            <RouterLink to='/app'>
              <Logo isGe={isGeDashboard} short className={classes.logoOnButton} />
            </RouterLink>
            <Divider className={classes.hiddenDivider} />
            {hasProjects && navSections.map((group, groupIndex) => {
              return (
                <Fragment key={groupIndex}>
                  <Divider className={classes.divider} />
                  {group.items && group.items.map((section, index) => {
                    if (!section.showOnMenu) {
                      return ''
                    }

                    return (
                      <NavItem key={index} icon={section.UrlIcon} shortView={section.shortView} section={section} />
                    )
                  })}
                </Fragment>
              )
            })}
            <Divider className={classes.divider} />
            {/* {navSections.map((section, index) => {
              if (!section.showOnMenu) {
                return ''
              }

              return (
                <NavItem key={index} icon={section.UrlIcon} shortView={section.shortView} section={section} />
              )
            })} */}
            {/* <Divider className={classes.divider} /> */}
            <Settings />
          </Drawer>
          )
        : (
          <AppBar position='fixed' color='transparent' className={classes.mobileNav}>
            <Toolbar className={classes.mobileNavPaper}>
              {hasProjects && navSections.map((section, index) => {
                if (!section.showOnMenu) {
                  return ''
                }

                return (
                  <MobileNavItem key={index} icon={section.UrlIcon} section={section} />
                )
              })}
              <div className={classes.grow} />
              <Settings />
            </Toolbar>
          </AppBar>
          )}
    </>
  )
}

// NavBar.propTypes = {
//   openMenu: PropTypes.bool,
//   setOpenMenu: PropTypes.func
// }

export default memo(NavBar)
