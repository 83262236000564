export const libraryDevices = [
  {
    models: ['sun2000-36-ktl', 'sun2000-20-ktl', 'sun2000-60-ktl', 'trifase', 'sun2000-10-ktl', 'sun2000-12-ktl', 'sun2000-17-ktl', 'sun2000-70-ktl', 'sun2000-100-ktl', 'sun2000-50-ktl', 'sun2000-75-ktl', 'sun2000-63-ktl', 'sun2000-90-ktl', 'sun2000-15-ktl', 'sun2000-65-ktl', 'sun2000-95-ktl'],
    status: {},
    energy: 'Energia',
    voltage: 'Tensione',
    current: 'Corrente',
    power: 'Potenza'
    /* vac1: 'Tensione',
    iac1: 'Corrente',
    activepowertot: 'Potenza',
    totalenergy: 'Energia' */
  },
  {
    models: ['iskra-mt880', 'E650', 'LZQJ-XC'],
    status: {},
    impenergy: 'E. prelevata',
    expenergy: 'E. immessa'
    // rawimpenergy: 'rawimpenergy',
    // rawexpenergy: 'rawexpenergy'
  },
  {
    models: ['s502'],
    status: {},
    voltage: 'Tensione',
    current: 'Corrente',
    activepowertot: 'Potenza',
    impenergy: 'E. prelevata',
    expenergy: 'E. immessa'
  },
  {
    models: ['s504c', 's604e', 's534', 'dmg800', 'mid-xmc3f-80'],
    voltagel1: 'Tensione L1',
    voltagel2: 'Tensione L2',
    voltagel3: 'Tensione L3',
    currentl1: 'Corrente L1',
    currentl2: 'Corrente L2',
    currentl3: 'Corrente L3',
    activepowertot: 'Potenza',
    impenergy: 'E. prelevata',
    expenergy: 'E. immessa',
    status: {}
  },
  {
    models: ['sunmeter'],
    irradiation: 'irraggiamento',
    temperature: 'temperatura',
    status: {}
  },
  {
    models: ['virtual'],
    irradiation_avg_quarter_kwmq: 'Radiazione (kWh/mq)',
    status: {}
  }
]
